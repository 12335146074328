const images = document.querySelectorAll('.image');
const texte = document.querySelectorAll('.texte');
const articles = document.querySelectorAll('.article');
const liste = document.querySelectorAll('.liste')
const selected = document.querySelectorAll('.selected')
const element = document.querySelector('.tagsfull');
const upNav = document.getElementById('upNav');
const downNav = document.getElementById('downNav');
const more = document.getElementById('more');
const panelTexte = document.getElementById('paneltexte');
const caroussel = document.querySelector('.main-carousel');
const showImages = document.getElementById('showImages');

if(showImages){
  showImages.addEventListener('click', apercuListe);
  var curimgText = showImages.innerText;
}

function apercuListe(){
	for (let i = 0; i < images.length; i++) {
   	images[i].classList.toggle('in');
	};
  for (let i = 0; i < texte.length; i++) {
    texte[i].classList.toggle('d-none');
  };
  for (let i = 0; i < articles.length; i++) {
    articles[i].classList.toggle('imageligne');
  }
  if(showImages.innerText == curimgText){
      showImages.innerText = "Liste";
  }
    else{
      showImages.innerText = curimgText;
    }
}

const tagListInside = document.getElementById('taglistinside');
if(tagListInside){
tagListInside.addEventListener("mouseover", showList);
tagListInside.addEventListener("mouseout", hideList);
}

function showList(){
	for (let i = 0; i < selected.length; i++) {
	selected[i].style.display = 'none';
	}
}
function hideList(){
  	for (let i = 0; i < selected.length; i++) {
	selected[i].style.display = 'block';
	}
}

window.onload = setActive()

function setActive() {
  aObj = document.getElementById('taglist').getElementsByTagName('a');
  for(i=0;i<aObj.length;i++) {
    if(document.location.href.indexOf(aObj[i].href)>=0) {
      aObj[i].className='tagOn';
    }
  }
}

more.addEventListener("click", showTexte);
var curText = more.innerText;

function showTexte() {
  panelTexte.classList.toggle('in');
    if(more.innerText == curText){
      more.innerText = "Moins";
    }
    else{
      more.innerText = curText;
    }
}

upNav.addEventListener("click", moveNav);
downNav.addEventListener("click", moveNav);

function moveNav() {
    element.classList.toggle('nav');
	for (let i = 0; i < liste.length; i++) {
  	liste[i].style.maxHeight = 'calc(100vh - 138px)';
	}
  caroussel.classList.toggle('d-none');
}


var flkty = new Flickity('.main-carousel', {
  pageDots: false,
  wrapAround: true,
  autoPlay: 3000,
  resize: false,
  cellAlign: 'center',
  lazyLoad: 1,
  draggable: true,
})

var carouselStatus = document.querySelector('.carousel-status');

function updateStatus() {
  var slideNumber = flkty.selectedIndex + 1;
  carouselStatus.textContent = slideNumber + '/' + flkty.slides.length;
}
updateStatus();

flkty.on( 'change', updateStatus );